import { Button } from "@/components/Button";
import { DatePicker } from "@/components/CalendarNew";
import { confirm } from "@/components/ConfirmationDialog";
import { DropdownSelect } from "@/components/DropdownSelect";
import { Input } from "@/components/Forms";
import { CalendarUnfilled } from "@/components/Icons/Calendar";
import { CloseUnfilled } from "@/components/Icons/Close";
import { Spinner } from "@/components/Icons/Spinner";
import { contacts } from "@/config/settings";
import { useUser } from "@/lib/auth/useUser";
import { disabledDateByBookingWindowHandler } from "@/lib/bookingWindow";
import { useCart } from "@/lib/cart";
import {
    overrideDateTimezone,
    stripDateTimezone,
    tzKL,
} from "@/lib/date-fns-util";
import dayjs from "@/lib/dayjs";
import { graphql } from "@/lib/gql";
import type {
    DateRangeFilter,
    PelangganAvailabilityViewQuery,
} from "@/lib/gql/graphql";
import { formatCents } from "@/utils";
import { useQuery } from "@apollo/client";
import { TZDate, tz } from "@date-fns/tz";
import * as Popover from "@radix-ui/react-popover";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cx } from "class-variance-authority";
import {
    addDays,
    addHours,
    addMinutes,
    addWeeks,
    areIntervalsOverlapping,
    compareDesc,
    format,
    isAfter,
    isBefore,
    isEqual,
    parseISO,
    startOfToday,
    subDays,
    subHours,
} from "date-fns";
import type { TFunction } from "i18next";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { haveDurationOpts, isStartTimeWithin } from "./liveAvailability";

const query = graphql(`
    query pelangganAvailabilityView($tenantId: ID!, $isLoggedIn: Boolean!) {
        categories {
            uid
            name
        }
        onlineServices(tenantId: $tenantId) {
            uid
            name
            serviceMode
            minBookingWindowMinutes
            bookingWindow
            metadata
            customDurationOptions
            disallowBookingGap
            resources {
                uid
                archived
                resource {
                    uid
                    name
                }
            }
            serviceCategory {
                uid
                categoryId
            }
        }
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)
    }
`);
const unavailabilityQuery = graphql(`
    query pelangganAvailabilityViewUnavailabilities(
        $tenantId: ID!
        $categoryId: ID!
        $dtRange: DateRangeFilter!
    ) {
        unavailableResourceSlotsBetween(
            categoryId: $categoryId
            dtRange: $dtRange
            tenantId: $tenantId
        ) {
            resourceId
            startDt
            endDt
        }
    }
`);
// TODO: selector form styling
// TODO: daily service handling (to handle in parent component)
const tNs = ["components/AvailabilitySchedule", "common"] as const;
type TFunc = TFunction<typeof tNs>;
type AvailabilityViewValues = { date: Date; categoryId: string };
type AvailabilityViewProps = {
    tenantId: string;
    initialCategoryId?: string;
    onSubmit: (v: AvailabilityViewValues) => void;
    inTZ?: string;
};
export const AvailabilityView = ({
    tenantId,
    initialCategoryId,
    onSubmit,
    inTZ = tzKL,
}: AvailabilityViewProps): JSX.Element => {
    const user = useUser();
    const [now] = useState(TZDate.tz(inTZ));
    const { state } = useCart();
    const [categoryId, setCategoryId] = useState(initialCategoryId ?? "");
    const [serviceId, setServiceId] = useState("");
    const [date, setDate] = useState<Date>();
    const { data } = useQuery(query, {
        variables: { tenantId, isLoggedIn: !!user },
        fetchPolicy: "cache-and-network",
    });

    const { t } = useTranslation(tNs);
    const selectedService = data?.onlineServices.find(isUid(serviceId));
    const dtRange = serviceUnavailabilityDtRange(selectedService, date);
    const schedDtRange = getScheduleDtRange(selectedService, date);

    const { data: unavailabilitiesData } = useQuery(unavailabilityQuery, {
        variables: { categoryId, tenantId, dtRange: dtRangeISOString(dtRange) },
        skip: !categoryId || !dtRange,
        fetchPolicy: "cache-and-network",
    });
    const userServices = data?.onlineServices.filter(
        (s) =>
            s.serviceMode === "HOURLY_SERVICE" &&
            (data.myMemberServiceIds?.includes(s.uid) ||
                !data.serviceTags.some((st) => st.serviceId === s.uid)),
    );
    const categories = data?.categories.filter((c) =>
        userServices?.some(isCategory(c.uid)),
    );
    const services = userServices?.filter(isCategory(categoryId));

    const unavailabilities =
        unavailabilitiesData?.unavailableResourceSlotsBetween?.map((ua) => ({
            resourceId: ua.resourceId,
            startDt: parseISO(ua.startDt),
            endDt: parseISO(ua.endDt),
        }));

    const resources = selectedService?.resources
        .filter(isNotArchived)
        .map((r) => r.resource)
        .sort(byName);

    const maxDate = selectedService?.bookingWindow
        ? addWeeks(
              startOfToday({ in: tz(inTZ) }),
              selectedService.bookingWindow,
          )
        : undefined;
    const isWithinMaxDate =
        !maxDate || (!!dtRange && isBefore(dtRange.startDt, maxDate));
    const cart = state.carts.find((c) => c.tenantId === tenantId);
    const lastCartSlotDate = cart?.slots
        .map((v) => v.timeDateFns)
        .sort(compareDesc)[0];

    return (
        <div className="flex w-full flex-1 flex-col gap-2 pt-4 lg:p-0 lg:px-4">
            <div>
                <DropdownSelect
                    value={categoryId}
                    onValueChange={(id) => {
                        setCategoryId(id);
                        const service = userServices?.filter(isCategory(id));
                        if (service?.length === 1 && service?.[0]?.uid) {
                            setServiceId(service[0].uid);
                            const maxDate =
                                service[0].bookingWindow &&
                                addWeeks(
                                    startOfToday({ in: tz(inTZ) }),
                                    service[0].bookingWindow,
                                );
                            if (date && maxDate && isAfter(date, maxDate))
                                setDate(undefined);
                            if (date && isBefore(date, now)) setDate(undefined);
                        } else setServiceId("");
                    }}
                    options={categories?.map(toOpt)}
                    placeholder={t("selectSport", "Select sport")}
                />
            </div>
            <div>
                <DropdownSelect
                    disabled={!categoryId}
                    value={serviceId}
                    onValueChange={(id) => {
                        setServiceId(id);

                        const service = services?.find((s) => s.uid === id);
                        if (!service) return;
                        const maxDate =
                            service.bookingWindow &&
                            addWeeks(
                                startOfToday({ in: tz(inTZ) }),
                                service.bookingWindow,
                            );
                        if (date && maxDate && isAfter(date, maxDate))
                            setDate(undefined);
                        if (date && isBefore(date, now)) setDate(undefined);
                    }}
                    options={services?.map(toOpt)}
                    placeholder={t("selectService", "Select service")}
                />
            </div>
            <div className="mx-[1px]">
                <DatePicker
                    disabled={disabledDateByBookingWindowHandler({
                        minMinutes: selectedService?.minBookingWindowMinutes,
                        maxWeeks: selectedService?.bookingWindow,
                    })}
                    // This is to "trick" DayPicker so that it shows the right date selected regardless of device timezone
                    selected={date && stripDateTimezone(date)}
                    onSelect={(day) => {
                        if (!day) return;
                        // This means that we will be dealing with midnight in the centre timezone
                        setDate(overrideDateTimezone(day, inTZ));
                    }}
                >
                    <Input
                        disabled={!serviceId}
                        placeholder={t("common:date", "Date")}
                        value={date ? format(date, "dd MMM yyyy, eeee") : ""}
                        suffixDiv={
                            <CalendarUnfilled className="size-5 text-blue-grey-200" />
                        }
                    />
                </DatePicker>
            </div>
            <Legend t={t} />
            {!!selectedService &&
                !!schedDtRange &&
                !!resources &&
                isWithinMaxDate && (
                    <Schedule
                        tenantId={tenantId}
                        categoryId={categoryId}
                        minTime={now}
                        range={schedDtRange}
                        resources={resources}
                        unavailabilities={unavailabilities}
                        selectedService={selectedService}
                        t={t}
                    />
                )}
            <div className="sticky bottom-0 bg-white pt-2">
                <Button
                    className="w-full"
                    onClick={() => {
                        let d = date;
                        if (lastCartSlotDate) {
                            d = lastCartSlotDate;
                        }
                        if (!d || !categoryId) return;
                        onSubmit({ date: d, categoryId: categoryId });
                    }}
                    disabled={!categoryId || (!date && !lastCartSlotDate)}
                >
                    {t("common:buttonBookNow", "Book Now")}
                </Button>
            </div>
        </div>
    );
};

const Legend = ({ t }: { t: TFunc }): JSX.Element => (
    <dl className="typography-tiny flex items-center justify-center gap-5 overflow-x-auto py-4">
        <div className="flex flex-row-reverse gap-3">
            <dt className="flex items-center">
                {t("unavailable", "Unavailable")}
            </dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-transparent bg-blue-grey-100">
                <span className="sr-only">Grey</span>
            </dd>
        </div>
        <div className="flex flex-row-reverse gap-3">
            <dt className="flex items-center">{t("available", "Available")}</dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-transparent bg-primary">
                <span className="sr-only">Blue</span>
            </dd>
        </div>
        <div className="flex flex-row-reverse gap-3">
            <dt className="flex items-center">{t("selected", "Selected")}</dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-success-600 bg-success-100">
                <span className="sr-only">Green</span>
            </dd>
        </div>
    </dl>
);

type Unavailability = { resourceId: string; startDt: Date; endDt: Date };
type SelectedServiceProp = PelangganAvailabilityViewQuery["onlineServices"][0];
type ScheduleProps = {
    tenantId: string;
    categoryId: string;
    minTime: Date;
    range: DtRange;
    resources: EntityWithName[];
    unavailabilities?: Unavailability[];
    selectedService: SelectedServiceProp;
    t: TFunc;
};
const Schedule = (props: ScheduleProps): JSX.Element => {
    // Show past slots up to 20 mins or show future slots starting from min booking window
    const minBookingWindow = props.selectedService?.minBookingWindowMinutes
        ? props.selectedService?.minBookingWindowMinutes
        : -20;
    const minTime = addMinutes(props.minTime, minBookingWindow);

    let i = props.range.startDt;
    const times: Date[] = [];
    let endDt = props.range.endDt;
    if (isEqual(i, endDt)) endDt = addDays(endDt, 1);
    // Add close col if scheduler start and end doesn't match
    const is24Hrs = isEqual(subDays(endDt, 1), i);
    while (isBefore(i, endDt) || (!is24Hrs && isEqual(i, endDt))) {
        if (isBefore(i, minTime)) {
            i = addMinutes(i, 30);
            continue;
        }
        times.push(i);
        i = addMinutes(i, 30);
    }

    return (
        <div
            className="my-2 grid flex-1 gap-y-3 overflow-auto pb-2"
            style={{
                gridTemplateColumns: `[${gridH}] max-content ${times.map((t) => `[${colT(t)}] 48px`).join(" ")}`,
                gridTemplateRows: `[${gridH}] 40px ${props.resources.map((r) => `[${r.uid}] 37px`).join(" ")}`,
            }}
        >
            {props.resources.map((r) =>
                times.map((t) => (
                    <AvailabilityCell
                        tenantId={props.tenantId}
                        categoryId={props.categoryId}
                        serviceName={props.selectedService.name}
                        serviceId={props.selectedService.uid}
                        key={`c-${r.uid}-${colT(t)}`}
                        resource={r}
                        time={t}
                        unavailabilities={props.unavailabilities}
                        range={props.range}
                        customDurationOptions={
                            props.selectedService.customDurationOptions
                        }
                        disallowBookingGap={
                            props.selectedService.disallowBookingGap
                        }
                        minBookingWindow={
                            props.selectedService.minBookingWindowMinutes
                        }
                        t={props.t}
                    />
                )),
            )}
            {times.map((t) =>
                t.getMinutes() === 0 || t.getMinutes() === 30 ? (
                    <div
                        key={`h-${colT(t)}`}
                        className="sticky top-0 col-span-2 flex items-center border-0 border-l-[0.5px] border-solid border-blue-grey-50 bg-white text-center"
                        style={{ gridRow: gridH, gridColumn: colT(t) }}
                    >
                        {format(t, "h:mm a")}
                    </div>
                ) : undefined,
            )}
            {props.resources.map((r) => (
                <div
                    key={`h-${r.uid}`}
                    className="sticky left-0 flex items-center justify-center bg-white pr-4"
                    style={{ gridColumn: gridH, gridRow: r.uid }}
                >
                    {r.name}
                </div>
            ))}
            <div
                className="sticky left-0 top-0 flex items-center justify-center bg-white"
                style={{ gridColumn: gridH, gridRow: gridH }}
            >
                <Spinner
                    className={cx(
                        "size-5",
                        !!props.unavailabilities && "hidden",
                    )}
                />
            </div>
        </div>
    );
};

const defaultDurationOptions = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
const cellContainerClass =
    "all-unset group cursor-pointer disabled:cursor-not-allowed border-0 border-l-[0.5px] border-solid border-blue-grey-100 px-[1px]";
type AvailabilityCellProps = {
    tenantId: string;
    categoryId: string;
    serviceName: string;
    serviceId: string;
    time: Date;
    unavailabilities?: Unavailability[];
    range: DtRange;
    customDurationOptions?: number[] | null;
    disallowBookingGap: boolean;
    minBookingWindow?: number | null;
    resource: { uid: string; name: string };
    t: TFunc;
};
const AvailabilityCell = (props: AvailabilityCellProps): JSX.Element => {
    const { state, dispatch } = useCart();
    const [open, setOpen] = useState(false);
    const cart = state.carts.find((c) => c.tenantId === props.tenantId);
    const matchingSlot = cart?.slots.find(
        (s) =>
            s.resourceId === props.resource.uid &&
            isStartTimeWithin(props.time, {
                startDt: s.time.toDate(),
                endDt: addHours(s.time.toDate(), s.duration),
            }),
    );
    const gridStyle = {
        gridColumn: colT(props.time),
        gridRow: props.resource.uid,
    };

    const customDurationOptions = props.customDurationOptions
        ?.map((cdo) => cdo / 60)
        .sort((a, b) => a - b);
    const durations = customDurationOptions?.length
        ? customDurationOptions
        : defaultDurationOptions;

    const bookedSlot = !!props.unavailabilities?.some(
        (u) =>
            u.resourceId === props.resource.uid &&
            isStartTimeWithin(props.time, u),
    );

    const isDurationAvailable = haveDurationOpts(
        props.time,
        props.resource.uid,
        props.range,
        props.disallowBookingGap,
        durations,
        props.unavailabilities,
    );

    if (matchingSlot) {
        return (
            <button
                type="button"
                className={cellContainerClass}
                style={gridStyle}
                onClick={() => {
                    dispatch({
                        type: "toggleSlot",
                        slot: matchingSlot,
                        tenantId: props.tenantId,
                    });
                }}
            >
                <div className="m-auto mx-[5px] size-[35px] rounded-full border border-solid border-success-600 bg-success-100" />
            </button>
        );
    }

    const is24Hrs = isEqual(subDays(props.range.endDt, 1), props.range.startDt);

    return (
        <Popover.Root open={open} onOpenChange={setOpen} modal>
            <Popover.Trigger
                data-unavailable={isDurationAvailable}
                className={cellContainerClass}
                disabled={bookedSlot || isEqual(props.range.endDt, props.time)}
                style={gridStyle}
            >
                {isEqual(props.range.endDt, props.time) && !is24Hrs ? (
                    <div className="mx-[5px] flex size-[35px] items-center justify-center rounded-full bg-blue-grey-200">
                        <CloseUnfilled className="text-blue-grey-900" />
                    </div>
                ) : (
                    <div className="m-auto mx-[5px] flex size-[35px] items-center justify-center rounded-full border border-solid border-transparent bg-primary group-disabled:bg-blue-grey-100 group-data-[unavailable=true]:bg-primary-200 group-data-[unavailable=true]:group-disabled:bg-blue-grey-100" />
                )}
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    collisionStrategy="shift"
                    side="top"
                    data-unavailable={isDurationAvailable}
                    className="z-50 flex w-screen flex-col rounded-2xl border bg-white px-4 py-5 shadow-2xl outline-none data-[unavailable=true]:mx-4 data-[unavailable=false]:h-screen data-[unavailable=false]:w-screen data-[unavailable=true]:w-[calc(100vw-32px)] data-[unavailable=true]:bg-primary-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 lg:h-[500px] lg:w-[343px] data-[unavailable=false]:lg:h-[500px] data-[unavailable=true]:lg:h-auto data-[unavailable=false]:lg:w-[343px] data-[unavailable=true]:lg:w-[343px]"
                >
                    {isDurationAvailable ? (
                        <DurationUnavaibleDiv t={props.t} />
                    ) : (
                        <>
                            <div className="typography-h3 mb-4 flex items-center justify-between border-0 border-b-[0.5px] border-solid border-blue-grey-200 pb-4 font-bold text-blue-grey-900">
                                {props.t("selectDuration", "Select duration")}
                                <Popover.Close className="all-unset">
                                    <CloseUnfilled className="text-blue-grey-600" />
                                </Popover.Close>
                            </div>
                            <DurationForm
                                tenantId={props.tenantId}
                                serviceName={props.serviceName}
                                serviceId={props.serviceId}
                                resource={props.resource}
                                time={props.time}
                                durations={durations}
                                t={props.t}
                                onSubmit={(v) => {
                                    const minBookingWindow =
                                        props.minBookingWindow
                                            ? -props.minBookingWindow
                                            : 20;
                                    const slot = {
                                        time: dayjs(props.time),
                                        timeDateFns: props.time,
                                        duration: v.duration,
                                        resourceId: props.resource.uid,
                                        resourceName: props.resource.name,
                                        price: v.price,
                                        deposit: v.deposit,
                                        serviceId: props.serviceId,
                                        serviceMode: "HOURLY_SERVICE" as const,
                                        expiry: dayjs(
                                            addMinutes(
                                                props.time,
                                                minBookingWindow,
                                            ),
                                        ),
                                        expiryDateFns: addMinutes(
                                            props.time,
                                            minBookingWindow,
                                        ),
                                        addons: [],
                                    };
                                    if (!cart) {
                                        dispatch({
                                            type: "newCart",
                                            tenantId: props.tenantId,
                                        });
                                    }
                                    if (cart?.categoryId !== props.categoryId) {
                                        confirm({
                                            title: props.t(
                                                "cartSlotResetTitle",
                                                "Switching to another category?",
                                            ),
                                            description: props.t(
                                                "cartSlotResetMessage",
                                                "You cannot checkout bookings of multiple categories at one go. Your cart must be emptied first to add this booking to your cart.",
                                            ),
                                            okText: props.t(
                                                "cartSlotResetOkText",
                                                "Empty my cart",
                                            ),
                                            okVariant: "destructivePrimary",
                                            cancelText: props.t(
                                                "common:buttonCancel",
                                                "Cancel",
                                            ),
                                            cancelVariant: "tertiary",
                                            onOkClick: async () =>
                                                dispatch({
                                                    type: "setCartItems",
                                                    tenantId: props.tenantId,
                                                    categoryId:
                                                        props.categoryId,
                                                    addOns: [],
                                                    slots: [slot],
                                                }),
                                        });
                                    } else {
                                        dispatch({
                                            type: "toggleSlot",
                                            tenantId: props.tenantId,
                                            slot,
                                        });
                                    }
                                    setOpen(false);
                                }}
                            />
                        </>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

const DurationUnavaibleDiv = ({ t }: { t: TFunc }) => (
    <>
        <div className="typography-h3 mb-4 flex items-center justify-between border-0 border-b-[0.5px] border-solid border-blue-grey-200 pb-4 font-bold text-blue-grey-900">
            {t("durationUnavailbleTitle", "Unable to Book This Time Slot")}
            <Popover.Close className="all-unset">
                <CloseUnfilled className="text-blue-grey-600" />
            </Popover.Close>
        </div>
        <div>
            {t(
                "durationUnavailbleMessage",
                "Your current selection either creates a 30-minute gap between existing bookings or does not meet the required booking duration. Please select another available time slot highlighted in dark blue.",
            )}
        </div>
    </>
);

type DurationFormValues = {
    duration: number;
    price: number;
    deposit: number;
    remainder: number;
};
const availabilityQuery = graphql(`
    query pelangganAvailabilityViewDurationForm(
        $req: [ServiceAvailabilityRequest!]!
        $quotReq: [QuotationRequest!]!
    ) {
        serviceAvailabilities(request: $req) {
            isAvailable
        }
        quotations(request: $quotReq) {
            deposit
            remainder
            price
            satisfiesMinimumDuration
            timeFullyAccounted
        }
    }
`);

const DurationForm = (props: {
    tenantId: string;
    serviceId: string;
    serviceName: string;
    resource: { uid: string; name: string };
    time: Date;
    durations: number[];
    onSubmit: (v: DurationFormValues) => void;
    t: TFunc;
}): JSX.Element => {
    const { control, handleSubmit } = useForm<{ duration: string }>();

    const { state } = useCart();
    const cart = state.carts.find((c) => c.tenantId === props.tenantId);
    const hasCartConflict = (d: number): boolean => {
        if (!cart) return false;
        return cart.slots.some(
            (s) =>
                s.resourceId === props.resource.uid &&
                areIntervalsOverlapping(
                    {
                        start: s.time.toDate(),
                        end: addHours(s.time.toDate(), s.duration),
                    },
                    { start: props.time, end: addHours(props.time, d) },
                ),
        );
    };
    const { data, loading } = useQuery(availabilityQuery, {
        fetchPolicy: "cache-and-network",
        variables: {
            req: props.durations.map((d) => ({
                tenantId: props.tenantId,
                startDt: props.time.toISOString(),
                endDt: addHours(props.time, d).toISOString(),
                resourceId: props.resource.uid,
                serviceId: props.serviceId,
            })),
            quotReq: props.durations.map((d) => ({
                tenantId: props.tenantId,
                start: props.time.toISOString(),
                end: addHours(props.time, d).toISOString(),
                setFor: props.serviceId,
            })),
        },
    });
    const options = props.durations
        .map((d, i) => ({
            duration: d,
            price: data?.quotations?.[i]?.price || 0,
            deposit: data?.quotations?.[i]?.deposit || 0,
            remainder: data?.quotations?.[i]?.remainder || 0,
            isAvailable:
                !hasCartConflict(d) &&
                !!data?.serviceAvailabilities?.[i]?.isAvailable &&
                !!data.quotations?.[i]?.timeFullyAccounted &&
                !!data.quotations[i]?.satisfiesMinimumDuration,
        }))
        .filter((o) => o.isAvailable);

    if (!loading && options.length === 0)
        return (
            <div className="italic">
                {props.t(
                    "noOptionAvailableMessage",
                    "There are no options to select for {{resourceName}} on the {{time}} under service {{serviceName}}. Please take a screenshot of this error and contact us at {{phoneNumber}} or {{email}} for assistance.",
                    {
                        resourceName: props.resource.name,
                        time: format(props.time, "dd MMM yyyy, h:mma"),
                        serviceName: props.serviceName,
                        phoneNumber: contacts.phoneNumber,
                        email: contacts.email,
                    },
                )}
            </div>
        );
    return (
        <form
            onSubmit={handleSubmit((v) => {
                const o = options.find(
                    (o) => o.duration.toString() === v.duration,
                );
                if (!o) return;
                props.onSubmit({
                    duration: o.duration,
                    deposit: o.deposit,
                    price: o.price,
                    remainder: o.remainder,
                });
            })}
            className="flex min-h-0 flex-1 flex-col justify-between"
        >
            <div className="flex flex-col gap-4 overflow-y-auto">
                <div>
                    <div className="typography-overline-lg text-primary">
                        {props.resource.name}
                    </div>
                    <div className="typography-overline text-blue-grey-900">
                        {format(props.time, "dd MMM yyyy, iiii")}
                    </div>
                </div>
                {loading ? (
                    <Spinner />
                ) : (
                    <Controller
                        control={control}
                        name="duration"
                        render={({ field }) => (
                            <ToggleGroup.Root
                                type="single"
                                orientation="horizontal"
                                className="mr-2 flex flex-col gap-3 lg:mr-1"
                                value={field.value}
                                onValueChange={field.onChange}
                            >
                                {options.map((d) => (
                                    <ToggleGroup.Item
                                        key={d.duration}
                                        value={d.duration.toString()}
                                        className="flex min-w-[80px] items-center justify-between rounded-lg border border-solid border-blue-grey-50 bg-white px-2 py-3 data-[state='on']:border-primary"
                                    >
                                        <div className="flex items-center gap-1 text-left">
                                            <div className="text-blue-grey-900">
                                                {format(props.time, "h:mma - ")}
                                                {format(
                                                    addHours(
                                                        props.time,
                                                        d.duration,
                                                    ),
                                                    "h:mma",
                                                )}
                                            </div>
                                            <div className="typography-sub text-blue-grey">
                                                (
                                                {props.t(
                                                    "hour",
                                                    `${d.duration} hr(s)`,
                                                    {
                                                        count: d.duration,
                                                    },
                                                )}
                                                )
                                            </div>
                                        </div>
                                        <div className="typography-main text-blue-grey-900">
                                            {formatCents(d.price)}
                                        </div>
                                    </ToggleGroup.Item>
                                ))}
                            </ToggleGroup.Root>
                        )}
                    />
                )}
            </div>
            <Button type="submit" className="mt-5 w-full">
                {props.t("addToCart", "Add to Cart")}
            </Button>
        </form>
    );
};

// TODO: switch to schedule
const maxDurationHours = 6;

const gridH = "header";
const colT = (d: Date): string => `t${format(d, "yyyyMMddHHmm")}`;

type HasName = { name: string };
const byName = <T extends HasName>(a: T, b: T): number =>
    a.name.localeCompare(b.name, "en-MY", { numeric: true });

const isUid =
    (id: string) =>
    (e: { uid: string }): boolean =>
        e.uid === id;
const isNotArchived = (e: { archived?: string | null }): boolean => !e.archived;
const isCategory =
    (c: string) =>
    (s: { serviceCategory?: { categoryId: string } | null }): boolean =>
        s.serviceCategory?.categoryId === c;

type EntityWithName = { uid: string; name: string };
type Option = { value: string; label: string };
const toOpt = (v: EntityWithName): Option => ({ value: v.uid, label: v.name });

type DtRange = { startDt: Date; endDt: Date };
const serviceUnavailabilityDtRange = (
    service: HasMetadata | undefined,
    date: Date | undefined,
): DtRange | undefined => {
    if (!service) return undefined;
    const selectorRange = parseServiceSelectorRange(service);
    return getUnavailabilityDtRangeFromParts(date, selectorRange);
};

const getUnavailabilityDtRangeFromParts = (
    date: Date | undefined,
    range: SelectorRange | undefined,
): DtRange | undefined => {
    if (!date) return undefined;
    const startDt = addHours(date, range?.start ?? 0);
    const endDt = addDays(
        addHours(date, range?.end ?? 0),
        range && range.start >= range.end ? 1 : 0,
    );
    return getUnavailabilityDtRange(startDt, endDt);
};

// 1 hour added/subtracted for booking gap calculations
const getUnavailabilityDtRange = (s: Date, e: Date): DtRange => ({
    startDt: subHours(s, 1),
    endDt: addHours(e, maxDurationHours + 1),
});

const getScheduleDtRange = (
    service: HasMetadata | undefined,
    date: Date | undefined,
): DtRange | undefined => {
    if (!service) return undefined;
    const selectorRange = parseServiceSelectorRange(service);
    return getScheduleDtRangeFromParts(date, selectorRange);
};

const getScheduleDtRangeFromParts = (
    date: Date | undefined,
    range: SelectorRange | undefined,
): DtRange | undefined => {
    if (!date) return undefined;
    const startDt = addHours(date, range?.start ?? 0);
    const endDt = addDays(
        addHours(date, range?.end ?? 0),
        range && range.start >= range.end ? 1 : 0,
    );
    return { startDt, endDt };
};

const dtRangeISOString = (r?: DtRange): DateRangeFilter => {
    if (!r) return { startDt: "", endDt: "" };
    return {
        startDt: r.startDt.toISOString(),
        endDt: r.endDt.toISOString(),
    };
};

type ServiceMetadata = {
    bookingSelectorStart?: string;
    bookingSelectorEnd?: string;
};
type SelectorRange = { start: number; end: number };
type HasMetadata = { metadata: string };
const parseServiceSelectorRange = (s: HasMetadata): SelectorRange => {
    const { bookingSelectorStart, bookingSelectorEnd }: ServiceMetadata =
        JSON.parse(s.metadata);
    const start = bookingSelectorStart
        ? Number.parseInt(bookingSelectorStart)
        : 0;
    const end = bookingSelectorEnd ? Number.parseInt(bookingSelectorEnd) : 24;
    return { start, end };
};
