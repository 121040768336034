const mastercardInteraction = {
    merchant: {
        name: "Courtsite",
        email: "contact@courtsite.my",
        phone: "+60378904143",
        url: "https://www.courtsite.my/",
        logo: "https://www.courtsite.my/icons/logomark140.png",
    },
    displayControl: {
        billingAddress: "HIDE",
    },
};

export const contacts = {
    email: "contact@courtsite.my",
    phoneNumber: "+603-78904143",
    waLink: "https://wa.me/60378904143",
};

const config = {
    EMAILJS_URL: "https://api.emailjs.com/api/v1.0/email/send",
    MASTERCARD_INTERACTION: mastercardInteraction,
};

export default config;
