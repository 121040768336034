import { TZDate, tz } from "@date-fns/tz";
import {
    addMinutes,
    addWeeks,
    addYears,
    isAfter,
    isBefore,
    isSameDay,
    startOfDay,
    subMinutes,
} from "date-fns";
import { overrideDateTimezone, tzKL } from "./date-fns-util";

const defaultOpts = {
    inTz: tzKL,
    leniencyMinutes: 20,
    nowFn: (inTz: string) => TZDate.tz(inTz),
};

// TODO: tests
export const disabledDateByBookingWindowHandler = (
    window: { minMinutes?: number | null; maxWeeks?: number | null },
    options?: {
        inTz?: string;
        leniencyMinutes?: number;
        nowFn?: (inTz: string) => Date;
    },
): ((d: Date) => boolean) => {
    const { inTz, leniencyMinutes, nowFn } = { ...defaultOpts, ...options };
    return (day: Date): boolean => {
        const d = overrideDateTimezone(day, inTz);
        const now = nowFn(inTz);
        const opt = { in: tz(inTz) };
        const disabledBefore =
            window.minMinutes || window.minMinutes === 0
                ? startOfDay(addMinutes(now, window.minMinutes), opt)
                : startOfDay(subMinutes(now, leniencyMinutes), opt);
        // intentional that maxWeeks === 0 is considered as no value set
        const disabledAfter = window.maxWeeks
            ? startOfDay(addWeeks(now, window.maxWeeks), opt)
            : startOfDay(addYears(now, 1), opt);
        if (isSameDay(d, disabledBefore, opt)) return false;
        if (isSameDay(d, disabledAfter, opt)) return true;
        return isBefore(d, disabledBefore) || isAfter(d, disabledAfter);
    };
};
