import {
    addHours,
    differenceInMinutes,
    isEqual,
    isWithinInterval,
    subDays,
} from "date-fns";

type DtRange = { startDt: Date; endDt: Date };
type Unavailability = { resourceId: string; startDt: Date; endDt: Date };

/** isStartTimeWithin checks if startDt is within unavailable datetime range [r.start, r.end). **/
export const isStartTimeWithin = (startDt: Date, r: DtRange) =>
    !isEqual(startDt, r.endDt) &&
    isWithinInterval(startDt, { start: r.startDt, end: r.endDt });

/** isEndTimeWithin checks if endDt is within unavailable datetime range [r.start, r.end). **/
const isEndTimeWithin = (endDt: Date, r: DtRange) =>
    !isEqual(endDt, r.startDt) &&
    isWithinInterval(endDt, { start: r.startDt, end: r.endDt });

/** isDurationOptAvailable checks if time difference between startDt and unavailble startDt exceeds duration. **/
const isDurationOptAvailable = (startDt: Date, r: DtRange, d: number) => {
    const diff = differenceInMinutes(r.startDt, startDt);
    return 0 < diff && diff < d * 60;
};

/** isDurationOptScheduleEndAvailable checks if time difference between startDt and schedule end exceeds duration. **/
const isDurationOptScheduleEndAvailable = (
    startDt: Date,
    scheduleEnd: Date,
    d: number,
) => {
    const diff = differenceInMinutes(scheduleEnd, startDt);
    return diff !== 0 && diff < d * 60;
};

/** isWithinScheduleEnd checks if there a booking gap conflict between endDt and scheduler endDt. **/
const isWithinScheduleEnd = (endDt: Date, scheduleEnd: Date) => {
    const diff = differenceInMinutes(scheduleEnd, endDt);
    return diff !== 0 && diff <= 30;
};

/** isWithinBookingGap checks if there a booking gap conflict between endDt and unavailable endDt. **/
const isWithinBookingGap = (endDt: Date, r: DtRange) => {
    const diff = differenceInMinutes(r.startDt, endDt);
    return diff > 0 && diff <= 30;
};

/**
 * Determines whether any of the provided duration options are available for booking
 * based on existing unavailabilities and scheduler constraints.
 *
 * A duration option is considered unavailable if:
 * - The start time (`t`) conflicts with an existing booking.
 * - The end time (`t`+`duration`) conflicts with an existing booking.
 * - The scheduler range doesnt form a 24-hour period (e.g start_dt != end_dt in hours) and end time (`t`+`duration`) conflicts with the scheduled end.
 * - The selected duration will create a booking gap with an existing booking or scheduled end, when booking gap is disallowed
 *
 * @param t - Start time of the potential booking.
 * @param resourceId - The resource being booked.
 * @param range - The scheduler's start and end time.
 * @param disallowBookingGap - Whether booking gaps are allowed.
 * @param durations - An array of possible duration options (in hours).
 * @param unavailabilities - A list of existing unavailability periods.
 * @returns `true` if at least one duration option is available, otherwise `false`.
 */

export const haveDurationOpts = (
    t: Date,
    resourceId: string,
    range: DtRange,
    disallowBookingGap: boolean,
    durations: number[],
    unavailabilities?: Unavailability[],
) => {
    const bookedSlot = !!unavailabilities?.some(
        (u) => u.resourceId === resourceId && isStartTimeWithin(t, u),
    );
    if (bookedSlot) return false;
    return !!unavailabilities?.some(
        (u) =>
            u.resourceId === resourceId &&
            durations.every((d) => {
                const end = addHours(t, d);

                const is24Hrs = isEqual(subDays(range.endDt, 1), range.startDt);

                const isTimeWithin =
                    isDurationOptAvailable(t, u, d) || isEndTimeWithin(end, u);

                if (disallowBookingGap) {
                    if (is24Hrs)
                        return isTimeWithin || isWithinBookingGap(end, u);

                    return (
                        isTimeWithin ||
                        isWithinBookingGap(end, u) ||
                        isWithinScheduleEnd(end, range.endDt)
                    );
                }

                if (!is24Hrs)
                    return (
                        isTimeWithin ||
                        isDurationOptScheduleEndAvailable(t, range.endDt, d)
                    );

                return isTimeWithin;
            }),
    );
};
