/**
 * Retrieves the base URL from environment variables.
 * Ensures that NEXT_PUBLIC_BASE_URL is defined.
 *
 * @returns {string} The base URL
 */
function getBaseUrl(): string {
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

    if (!baseUrl) {
        throw new Error(
            "NEXT_PUBLIC_BASE_URL is not defined. Ensure it's set in your .env file.",
        );
    }
    return baseUrl;
}

/**
 * Constructs the Centre URL for a given organisation,
 * optionally with a sub-path appended (e.g. /activities, /select).
 *
 * @param {string} orgName - The name of the organisation
 * @param {string} tenantId - The tenant ID of the organisation
 * @param {string} subPath - Optional additional sub-path (e.g. "activities", "select")
 * @returns {string} - The formatted URL
 */
export function getCentreURL(
    orgName: string,
    tenantId: string,
    subPath?: string,
): string {
    const centreURL = [
        getBaseUrl(),
        "centre",
        encodeURIComponent(orgName),
        tenantId,
        subPath,
    ].filter((v) => v);
    return centreURL.join("/");
}
