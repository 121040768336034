import { cx } from "class-variance-authority";

export const Spinner = (props: { className?: string }): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={56}
        height={56}
        viewBox="0 0 56 56"
        className={cx("animate-spin", props.className)}
    >
        <path
            fill="#EBEEFD"
            d="M56 28a28 28 0 1 1-56 0 28 28 0 0 1 56 0ZM7 28a21 21 0 1 0 42 0 21 21 0 0 0-42 0Z"
        />
        <path
            fill="#4361EE"
            d="M12 5a28 28 0 1 1 15.5 51l.1-7A21 21 0 1 0 16 10.8l-4-5.7Z"
        />
    </svg>
);
