import { AvailabilityView } from "@/components/AvailabilitySchedule/v2";
import dayjs from "@/lib/dayjs";
import { graphql } from "@/lib/gql";
import type { ViewLiveAvailabilityQuery } from "@/lib/gql/graphql";
import { byCategoryImportance, isUniqueBy } from "@/utils/index";
import { useQuery } from "@apollo/client";
import { useFlag } from "@unleash/proxy-client-react";
import { formatISO } from "date-fns";
import { type NextRouter, useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AvailabilitySchedule } from "./AvailabilitySchedule";
import { Button } from "./Button";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogSheet,
    DialogTrigger,
} from "./DialogSheet";
import { SearchFilled } from "./Icons";
import { Spinner } from "./Icons/Spinner";
import { ScrollArea } from "./ScrollArea";

export type ViewLiveAvailabilityButtonProps = {
    org: { uid: string; name: string };
};

export const ViewLiveAvailabilityButton = ({
    org,
}: ViewLiveAvailabilityButtonProps): JSX.Element => {
    const { t } = useTranslation("components/ViewLiveAvailability");
    const [open, setOpen] = useState(false);
    const router = useRouter();
    const isNew = useFlag("feature.liveAvailabilityRedesign");

    const { data, loading } = useQuery(query, {
        variables: { tenantId: org.uid },
        fetchPolicy: "cache-and-network",
    });

    const isAllDailyModeServices = !!data?.onlineServices.every(
        (s) => s.serviceMode === "DAILY_SERVICE",
    );

    let child = (
        <DialogContent side="right" className="!max-w-[1070px]">
            <DialogHeader>{org.name}</DialogHeader>
            <DialogDescription className="flex">
                <ScrollArea className="flex min-h-0 w-full flex-1">
                    <AvailabilityView
                        tenantId={org.uid}
                        onSubmit={(v) => {
                            setOpen(false);
                            router.push({
                                pathname: "/centre/[orgName]/[orgID]/select",
                                query: {
                                    orgName: org.name,
                                    orgID: org.uid,
                                    categoryId: v.categoryId,
                                    date: formatISO(v.date, {
                                        representation: "date",
                                    }),
                                },
                            });
                        }}
                    />
                </ScrollArea>
            </DialogDescription>
        </DialogContent>
    );
    if (!isNew || isAllDailyModeServices) {
        child = (
            <DialogContent side="right">
                <DialogHeader>{org.name}</DialogHeader>
                <DialogDescription className="flex">
                    <ScrollArea className="flex min-h-0 w-full flex-1">
                        <ViewAvailabilityOld
                            orgID={org.uid}
                            orgName={org.name}
                            setOpen={setOpen}
                            router={router}
                            data={data}
                        />
                    </ScrollArea>
                </DialogDescription>
            </DialogContent>
        );
    }

    if (loading)
        return (
            <div className="flex w-full items-center justify-center">
                <Spinner className="size-5" />
            </div>
        );
    return (
        <DialogSheet open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button
                    className="flex w-full gap-2 rounded p-3"
                    variant="outlined"
                    size="lg"
                >
                    <SearchFilled />
                    <div className="typography-sub text-wrap text-start font-bold capitalize">
                        {t("availability", "Availability")}
                    </div>
                </Button>
            </DialogTrigger>
            {child}
        </DialogSheet>
    );
};

type ViewAvailabilityButtonProps = {
    orgID: string;
    orgName: string;
    router: NextRouter;
    data?: ViewLiveAvailabilityQuery;
    setOpen: (v: boolean) => void;
};

const ViewAvailabilityOld = ({
    orgID,
    orgName,
    router,
    data,
    setOpen,
}: ViewAvailabilityButtonProps): JSX.Element => {
    const { t } = useTranslation("common");

    type SubmitValues = { categoryId?: string; date?: dayjs.Dayjs };
    const handleViewAvailabilitySubmit = (values: SubmitValues): void => {
        setOpen(false);
        router.push({
            pathname: "/centre/[orgName]/[orgID]/select",
            query: {
                orgName: orgName,
                orgID: orgID,
                date: values.date
                    ? values.date.tz().startOf("d").format("YYYY-MM-DD")
                    : undefined,
                categoryId: values.categoryId,
            },
        });
    };

    const onlineServices = useMemo(
        () =>
            data?.onlineServices.filter(
                ({ uid }) =>
                    !data.serviceTags.some((st) => st.serviceId === uid) ||
                    data.myMemberServiceIds.includes(uid),
            ) ?? [],
        [data],
    );

    const categories = onlineServices
        .map((s) => s.category)
        .filter(isUniqueBy((v) => v?.uid))
        .map((c) => ({
            uid: c?.uid ?? "",
            name: c?.name ?? "",
            metadata: c?.metadata ?? "",
            isAllDailyModeServices: onlineServices
                .filter((s) => s.category?.uid === c?.uid)
                .every((s) => s.serviceMode === "DAILY_SERVICE"),
        }))
        .sort(byCategoryImportance);

    return (
        <div className="flex h-full w-full flex-col items-center lg:min-h-[700px]">
            <AvailabilitySchedule
                tenantId={orgID}
                submitButtonText={t("book_now", "Book Now")}
                onSubmit={handleViewAvailabilitySubmit}
                scheduleRanges={(uid) =>
                    onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => {
                            const m = JSON.parse(s.metadata);
                            return {
                                start:
                                    Number.parseInt(
                                        m.bookingSelectorStart ?? "0",
                                    ) % 24,
                                end:
                                    Number.parseInt(
                                        m.bookingSelectorEnd ?? "24",
                                    ) % 24,
                            };
                        })
                }
                unavailableAfterDate={(uid) => {
                    const c = onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => s.bookingWindow ?? 0);
                    if (c.some((w) => !w)) {
                        return undefined;
                    }
                    const m = Math.max(...c);
                    return dayjs()
                        .tz()
                        .add(m * 7, "d")
                        .startOf("d");
                }}
                unavailableBeforeDate={(uid) => {
                    const c = onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => s.minBookingWindowMinutes);
                    if (!c.every((w): w is number => !w)) {
                        return dayjs().tz().startOf("d");
                    }
                    const m = Math.min(...c);
                    return dayjs().add(m, "m").startOf("d");
                }}
                categories={categories}
                resourceScheduleRanges={(uid) =>
                    onlineServices
                        .filter((s) =>
                            s.resources.some(
                                (sr) => !sr.archived && sr.resourceId === uid,
                            ),
                        )
                        .map((s) => {
                            const m = JSON.parse(s.metadata);
                            return {
                                start:
                                    Number.parseInt(
                                        m.bookingSelectorStart ?? "0",
                                    ) % 24,
                                end:
                                    Number.parseInt(
                                        m.bookingSelectorEnd ?? "24",
                                    ) % 24,
                            };
                        })
                }
            />
        </div>
    );
};

const query = graphql(`
    query viewLiveAvailability($tenantId: ID!) {
        onlineServices(tenantId: $tenantId) {
            uid
            name
            minBookingWindowMinutes
            bookingWindow
            disallowBookingGap
            metadata
            serviceMode
            category {
                uid
                name
                metadata
            }
            resources {
                uid
                resourceId
                archived
            }
            customDurationOptions
        }
        addOns(tenantId: $tenantId) {
            uid
            name
            price
            isInStock
        }
        myMemberServiceIds(tenantId: $tenantId)
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
    }
`);
